<template>
    <div class="campus-health">
        <heads></heads>
        <img class="top-bg" src="../../assets/images/campus_health_top_bg.png" alt="">
        <div class="campus-health-container">
            <div class="content" v-if="articleCategory.name != null && articleCategory.name != ''">
                <div class="introduce">
                    <p>{{ articleCategory.description }}</p>
                </div>
                <div class="display-or-list" :style="{
                    backgroundImage: 'url(' + articleCategory.video_background + ')',
                }">
                    <div class="video-box">

                    </div>
                </div>

                <div class="article-list"
                    :class="['87', '88', '89', '90', '91', '92', '93', '94', '95', '96', '97', '98', '99', '100'].includes(this.$route.query.id) ? 'justify-content-center' :
                        ['42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '101', '102', '103', '104', '105', '106', '107', '108', '109', '110', '111', '112'].includes(this.$route.query.id) ? 'row-reverse' : ''"
                    :style="{
                        backgroundImage: 'url(' + articleCategory.article_list_background + ')',
                    }">
                    <ul :class="this.$route.query.id == 87 ? 'width-id-87' : 'normal'">
                        <li v-for="(item, index) in articleList">
                            <router-link :to="{
                                path: '/campusHealth/detail',
                                query: { id: item.id }
                            }">
                                <span class="title">{{ item.name }}</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { getArticleCategoryParentById, getArticleList } from "@api/public";
export default {
    components: {
        heads,
        foot,
        swiper,
        swiperSlide,
    },
    data() {
        return {
            articleCategory: { children: [] },
            articleList: [],
            swiperOption: {
                direction: 'vertical',
                init: false,
                loop: true,
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false,
                },
                observer: true,
                observeParents: true,
            },
        };
    },
    watch: {
        $route(to, from) {
            this.$router.go(0);
        },
    },
    mounted: function () {
        let that = this;
        getArticleCategoryParentById(this.$route.query).then(function (res) {
            that.$set(that, "articleCategory", res.data);
        });
        getArticleList({ article_category_id: this.$route.query.id, limit: 15 }).then(function (res) {
            that.$set(that, "articleList", res.data.list);
        });
    },
    computed: {
        swiper: function () {
            return this.$refs.mySwiper.swiper;
        },
    },
    created: function () {
        document.title = this.$route.meta.title;
    },
};
</script>

<style scoped>
.campus-health {
    position: relative;
    width: 100%;
    height: auto;
    background-color: #3a657d;
}

.campus-health .top-bg {
    width: 1920px;
    height: 470px;
}

.campus-health .campus-health-container {
    position: relative;
    width: 1920px;
    height: auto;
    top: -205px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.campus-health .campus-health-container .content {
    position: relative;
    width: 960px;
    height: auto;
    display: flex;
    flex-direction: column;
}

.campus-health .campus-health-container .content .introduce {
    position: relative;
    width: 100%;
    height: 205px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.campus-health .campus-health-container .content .introduce p {
    position: relative;
    height: auto;
    max-height: 205px;
    font-size: 26px;
    overflow-y: auto;
    font-family: kaiti;
    text-indent: 2em;
    color: white;
    word-break: break-all;
    text-align: justify;
    margin-left: 70px;
    margin-right: 76px;
}

.campus-health .campus-health-container .content .display-or-list {
    position: relative;
    width: 100%;
    height: 540px;
    background-size: 960px 540px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.campus-health .campus-health-container .content .display-or-list .video-box {
    position: relative;
    width: 485px;
    height: 367px;
    background: url(../../assets/images/campus_health_video_play_border.jpg) 485px 367px no-repeat;
    background-size: cover;
    background-position: center 0;
    margin-right: 80px;
    /* border: 1px solid red; */
}

.campus-health .campus-health-container .content .article-list {
    position: relative;
    margin-top: 30px;
    width: 100%;
    height: 540px;
    background-size: 960px 540px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.campus-health .campus-health-container .content .article-list.justify-content-center {
    justify-content: center
}

.campus-health .campus-health-container .content .article-list.row-reverse {
    flex-direction: row-reverse;
}

.campus-health .campus-health-container .content .article-list ul {
    position: relative;
    margin-left: 30px;
    overflow-y: auto;
    /* border: 1px solid blue; */
}

.campus-health .campus-health-container .content .article-list ul.mormal {
    height: 85%;
}

.campus-health .campus-health-container .content .article-list ul.width-id-87 {
    height: 60% !important;
}

.campus-health .campus-health-container .content .article-list ul li {
    position: relative;
    font-size: 20px;
    font-family: kaiti;
    font-weight: 500;
}

.campus-health .campus-health-container .content .article-list ul li+li {
    margin-top: 12px;
}

.swiper-campus-health-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
</style>