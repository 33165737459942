var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"campus-health"},[_c('heads'),_c('img',{staticClass:"top-bg",attrs:{"src":require("../../assets/images/campus_health_top_bg.png"),"alt":""}}),_c('div',{staticClass:"campus-health-container"},[(_vm.articleCategory.name != null && _vm.articleCategory.name != '')?_c('div',{staticClass:"content"},[_c('div',{staticClass:"introduce"},[_c('p',[_vm._v(_vm._s(_vm.articleCategory.description))])]),_c('div',{staticClass:"display-or-list",style:({
                backgroundImage: 'url(' + _vm.articleCategory.video_background + ')',
            })},[_c('div',{staticClass:"video-box"})]),_c('div',{staticClass:"article-list",class:['87', '88', '89', '90', '91', '92', '93', '94', '95', '96', '97', '98', '99', '100'].includes(this.$route.query.id) ? 'justify-content-center' :
                    ['42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '101', '102', '103', '104', '105', '106', '107', '108', '109', '110', '111', '112'].includes(this.$route.query.id) ? 'row-reverse' : '',style:({
                    backgroundImage: 'url(' + _vm.articleCategory.article_list_background + ')',
                })},[_c('ul',{class:this.$route.query.id == 87 ? 'width-id-87' : 'normal'},_vm._l((_vm.articleList),function(item,index){return _c('li',[_c('router-link',{attrs:{"to":{
                            path: '/campusHealth/detail',
                            query: { id: item.id }
                        }}},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(item.name))])])],1)}),0)])]):_vm._e()]),_c('foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }